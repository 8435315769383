"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var radioButtons = document.querySelectorAll('input[type="radio"][name="filter"]');
  var items = document.querySelectorAll('.calendar-items .cell');
  radioButtons.forEach(function (radioButton) {
    radioButton.addEventListener("change", function () {
      var selectedMonth = this.value;
      items.forEach(function (item) {
        var month = item.getAttribute('data-month').toLowerCase();
        if (selectedMonth === "All" || selectedMonth === month) {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
    });
  });
});