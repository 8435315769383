"use strict";

jQuery(document).ready(function ($) {
  var _this = this;
  // Foundation initialization
  $(document).foundation();
  var menuBtn = document.querySelector('.menu-btn');
  var lineOne = document.querySelector(' .menu-btn .line--1');
  var lineTwo = document.querySelector(' .menu-btn .line--2');
  var lineThree = document.querySelector(' .menu-btn .line--3');
  var nav = document.querySelector('nav#site-navigation');
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('nav-open');
    lineOne.classList.toggle('line-cross');
    lineTwo.classList.toggle('line-fade-out');
    lineThree.classList.toggle('line-cross');
  });

  // Initialize fade-in animation once after page load
  function initFadeInAnimation() {
    $('.fade-in-letters').each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('visible');
      }
    });
  }

  // Trigger fade-in animation once after page load
  initFadeInAnimation();
  jQuery('.custom-file-upload-container .ginput_container_fileupload input[type=file]').change(function () {
    var fileName = $(this).val().split('\\').pop();
    var closestContainer = $(this).closest('.custom-file-upload-container').find('.custom-label');
    // Do something with the closest container
    closestContainer.text('Bestand: ' + fileName);
  });

  // Check if element is scrolled into view
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    // Adjusted condition to consider elements fully in view on page load
    return elemTop <= docViewBottom && elemBottom >= docViewTop;
  }

  // Handle fading elements on scroll
  function handleFadeInElements() {
    $('.fade-in').each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('animation-started');
      }
    });
  }

  // Handle navbar behavior on scroll
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('header').outerHeight();
  $(window).scroll(function () {
    var st = $(window).scrollTop();
    var scrollTopDifference = Math.abs(lastScrollTop - st);
    if (scrollTopDifference <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      $('header').removeClass('nav-down').addClass('nav-up');
    } else {
      if (st + $(window).height() < $(document).height()) {
        $('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  });

  // Touch event setup
  $.event.special.touchstart = {
    setup: function setup(_, ns, handle) {
      _this.addEventListener('touchstart', handle, {
        passive: !ns.includes('noPreventDefault')
      });
    }
  };
  $.event.special.touchmove = {
    setup: function setup(_, ns, handle) {
      _this.addEventListener('touchmove', handle, {
        passive: !ns.includes('noPreventDefault')
      });
    }
  };

  // Add scroll event listener to trigger fade-in animation when elements come into the viewport
  $(window).on('scroll', function () {
    return initFadeInAnimation();
  });

  // Parallax effect
  window.addEventListener("scroll", function () {
    var distance = window.scrollY;
    document.querySelector(".parallax-window").style.transform = "translateY(".concat(distance * 0.3, "px)");
  });
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });
});